// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import TRAFFIC_USAGE_BY_VIEWER_DOMAINS_PAGE from 'queries/TrafficUsageByViewerDomainsPage.graphql';
import TRAFFIC_USAGE_BY_VIEWER_DOMAINS_LIST from 'queries/TrafficUsageByViewerDomainsList.graphql';
import TOTAL_TRAFFIC_USAGE_BY_VIEWER_DOMAINS from 'queries/TotalTrafficUsageByViewerDomains.graphql';

export const trafficUsageByViewerDomainsRoute = {
    path: 'traffic-usage-domains',
    handle: {
        crumb: () => ({
            title: Locale.getSection('components.routes').lmsg('trafficUsageByDomains'),
        }),
    },
    children: [
        {
            index: true,
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/TrafficUsageByDomains/ViewerPage" */
                'components/pages/TrafficUsageByDomains/ViewerPage'
            ),
            loader: async () => {
                const trafficUsageListKey = 'TrafficUsageByViewerDomainsList';

                const [{ data: pageData }] = await Promise.all([
                    apolloClient.query({
                        query: TRAFFIC_USAGE_BY_VIEWER_DOMAINS_PAGE,
                    }),
                    apolloClient.query({
                        query: TOTAL_TRAFFIC_USAGE_BY_VIEWER_DOMAINS,
                    }),
                    apolloClient.query({
                        query: TRAFFIC_USAGE_BY_VIEWER_DOMAINS_LIST,
                        variables: {
                            input: readGraphQlListInputFromStorage({ key: trafficUsageListKey }),
                        },
                    }),
                ]);

                const { trafficUsageIntervals } = pageData.viewer.client.statistics;

                return {
                    trafficUsageIntervals,
                    trafficUsageListKey,
                };
            },
        },
    ],
};
